import GuidUtils from '@/shared/utils/guid'

export default class School {
  constructor({
    id,
    name,
    companyId,
    stateId,
    cityId
    
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.companyId = companyId,
    this.stateId = stateId,
    this.cityId = cityId
  }
}