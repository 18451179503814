import StateRepository from '@/shared/http/repositories/core/state'
import CityRepository from '@/shared/http/repositories/core/city'
import { loading } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  name: 'NewRegisterSchool',
  props: {
    placeholder: String,
    title: String,
    school: Object,
    isNew: Boolean,
  },

  data: () => ({
    states: [],
    cities: [],
  }),

  created() {
    this.getState()
  },

  watch: {
    'school.stateId': {
      handler: function() {
        this.getCitiesByState(this.school.stateId)
      }
    }
  },

  computed: {
    state() {
      if (this.school && this.school.name) {
        return this.school.name.length >= 6
      }
    },
    disable() {     
      if (this.isNew === true) {
        if (!this.school.cityID && !this.school.name)
          return true
        else {
          if (this.school.cityID && this.school.name)
            return this.school.name.length < 6
        }

      } else {
          if (this.school && this.school.name && this.school.cityID) {
            return this.school.name.length < 6
        }
      }
      return true
    }
  },

  methods: {
    backTo() {
      this.$router.go(-1)
    },

    save() {
      this.isNew 
        ? this.$emit('create', this.school)
        : this.$emit('update', this.school)
      },

    selectStateId() {
      this.$emit('selectState', this.stateId)
    },

    selectModalityId() {
      this.$emit('selectModality', this.school.modalityId)
    },

    async getState() {
      loading.push()
      await StateRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.states = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },

    getCitiesByState(stateId) {

      if(!stateId)
        return

      loading.push()
      CityRepository.GetByStateId(stateId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.cities = res.data.result

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    clearCity(){
      this.$emit('clearCity')
    }
  }
}