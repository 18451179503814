import SchoolRepository from '@/shared/http/repositories/socialProject/school'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegisterSchool from '@/components/layout/NewRegisterSchool/newRegisterSchool.vue'
import School from '@/shared/models/school'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovaEscola',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterSchool.name]: NewRegisterSchool
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Escola',
    school: new School(),
    isNew: true,
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Escolas',
            href: this.$router.resolve({ name: 'School' }).href
          },
          {
            text: 'Nova escola',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(school) {
      loading.push()
      SchoolRepository.Create(school)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Escola criada com sucesso', 'NOVA ESCOLA')
          Promise.resolve()
          return this.$router.replace({ name: 'School' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar escola', 'ERRO')
        })
    }
  }
}
